import { handleResponse, handleError } from "./apiUtils";

export function saveMessage(message) {
  return fetch("messages", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      ...message
    })
  })
    .then(handleResponse)
    .catch(handleError);
}
