import React from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();
  return (
    <div>
      <Parallax filter image={require("assets/img/banner_bg.jpeg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Myan Yu Transport</h1>
              <h4>
                We are a Myanmar-based, one-stop transportation service provider
                capable of tapping on the growing market demands and business
                opportunities in Myanmar. We aim to be the preferred choice to
                many growing companies in land support.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </div>
  );
}
