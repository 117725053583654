import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { saveMessage } from "../api/messageApi";
import Warning from "components/Typography/Warning.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

export default function ContactForm() {
  const [error, setError] = useState({});
  const [message, setMessage] = useState({
    name: "",
    email: "",
    message: ""
  });
  function handleChange({ target }) {
    setMessage({
      ...message,
      [target.id]: target.value
    });
  }

  function formIsValid() {
    const _error = {};

    if (!message.name) _error.name = "Name is required";
    if (!message.email) _error.email = "Email is required";
    if (!message.message) _error.message = "Message is required";

    setError(_error);
    // Form is valid if the errors object has no properties
    return Object.keys(_error).length === 0;
  }
  function handleSubmit(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    saveMessage(message).then(() => {
      setMessage({});
    });
  }
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <h3 className={classes.cardTitle}>Taunggyi</h3>
            <strong className={classes.smallTitle}>U Khin Maung Thein</strong>
            <CardBody>
              <p className={classes.description}>
                8/3 Nang Thida Road
                <br />
                Ye Aye Qwin Qtr, Taunggyi
                <br />
                081-21493, 09-785211169, 09-794251545
                <br />
                09-428318558, 09-5241020, 09-5104942
                <br />
                Ayetharyar: 081-208701, 081-208716
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card plain>
            <h3 className={classes.cardTitle}>Yangon</h3>
            <strong className={classes.smallTitle}>U Eike Kham</strong>
            <CardBody>
              <p className={classes.description}>
                Sa/34, Sein Pan Road
                <br />
                Bayint Naung Pyaw Yong Tan, Yangon
                <br />
                01-680518, 09-73156512, 09-31301086
                <br />
                09-974332507, 09-5104942
              </p>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Taunggyi</h2>
          <h4 className={classes.description}>8/3 Nang Thida Road </h4>
          <h4 className={classes.description}>Ye Aye Qwin Qtr</h4>
          <h4 className={classes.description}>
            Taunggyi: 081-21493, 09-785211169, 09-794251545
          </h4>
          <h4 className={classes.description}>
            09-428318558, 09-5241020, 09-5104942
          </h4>
        </GridItem> */}
        <GridItem cs={12} sm={12} md={8}>
          <h3 className={classes.title}>Contact us</h3>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  value={message.name}
                  inputProps={{
                    onChange: handleChange
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {error.name && <Warning>{error.name}</Warning>}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  value={message.email}
                  inputProps={{
                    onChange: handleChange
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {error.email && <Warning>{error.email}</Warning>}
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                value={message.message}
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  onChange: handleChange,
                  multiline: true,
                  rows: 5
                }}
              />
              {error.message && <Warning>{error.message}</Warning>}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                  <Button type="submit" color="primary">
                    Send Message
                  </Button>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
