import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function AboutPage() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={4}>
          <h2 className={classes.title}>About Us</h2>
          <h5 className={classes.description}>
            Myan Yu offers transportation services for both individuals and
            businesses in Myanmar. We have various type of trucks to deliver
            from small goods to large and bulky goods as our customer needs. We
            have setup GPS device in every track to make sure our customer{"'"}s
            goods are delivered on time.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
