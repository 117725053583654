import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
/* import { Link } from "@material-ui/core"; */

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product

import LandingPage from "views/LandingPage.js";
import AboutPage from "views/AboutPage.js";
import ContactPage from "views/ContactPage.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

var hist = createBrowserHistory();
const dashboardRoutes = [];

ReactDOM.render(
  <Router history={hist}>
    <Header
      color="rose"
      routes={dashboardRoutes}
      brand="Myan Yu"
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "white"
      }}
    >
      {/* <Link href="/" onClick={e => e.perventDefault()} /> */}
    </Header>
    <Switch>
      <Route path="/about" component={AboutPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
